import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-region-search',
    templateUrl: './region-search.component.html',
    styleUrls: ['./region-search.component.scss'],
})
export class RegionSearchComponent implements OnInit {
    @Output() search = new EventEmitter<string>();

    constructor() {}

    ngOnInit(): void {}
}
