<div id="back-header" [class.no-image]="!picture">
    <app-img-loader [file]="picture" [fitMode]="'fitBoth'" [preferredSize]="'xl'"></app-img-loader>
    <div id="back-container">
        <div id="click-container" (click)="navigateBack()" matRipple>
            <div id="place">
                <mat-icon>navigate_before</mat-icon>
            </div>

            <h4>Zurück</h4>
        </div>
    </div>
</div>

<div *ngIf="ready" class="content-container" [class.no-image]="!picture">
    <div id="image-container" *ngIf="picture">
        <div class="image"></div>
    </div>

    <!--<div id="kiga-logo">
        <app-img-loader *ngIf="kigaLogo" [file]="kigaLogo" [fitWidth]="false" [preferredSize]="'m'"></app-img-loader>
    </div>-->
    <p class="post-date">{{ post.pubDate | dateToFullDateString }}</p>

    <h2 id="title">{{ title }}</h2>

    <div id="intro" [innerHTML]="introSanitized"></div>

    <div id="content" [innerHTML]="contentSanitized"></div>

    <div id="youtube-container" *ngIf="sanitizedYoutubeEmbedLink">
        <iframe
            id="youtube-frame"
            [src]="sanitizedYoutubeEmbedLink"
            width="100%"
            height="100%"
            frameborder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        ></iframe>
    </div>

    <div id="attachment-container">
        <div *ngFor="let attachmentFile of attachmentFiles">
            <!--<p *ngIf="attachmentTitle" [innerHTML]="attachmentTitleSanitized"></p>-->
            <button mat-flat-button class="attachment-button" (click)="aElement.click()">
                {{ attachmentFile.title }} herunterladen
            </button>
            <a hidden [href]="attachmentFile.url" target="_blank" download #aElement></a>
        </div>
    </div>

    <mat-divider></mat-divider>

    <div id="social-container" fxLayoutGap="20px">
        <button mat-stroked-button fxFlex="50" (click)="likePost()">
            <mat-icon id="icon-like" *ngIf="!hasLiked">favorite_border</mat-icon>
            <mat-icon id="icon-like" *ngIf="hasLiked">favorite</mat-icon>
            {{ likes }} Likes
        </button>
        <!--<button mat-stroked-button fxFlex="50" (click)="sharePost()">
            <mat-icon id="icon-share">share</mat-icon>
            Teilen
        </button>-->
    </div>
</div>
