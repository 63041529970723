import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { LanguageContent } from '../models/language-content.interface';
import { Content } from '../models/content.interface';
import { PushNotificationService } from './push-notification.service';

@Injectable({
    providedIn: 'root',
})
export class PublicLanguageContentService {
    private static readonly BASE_URL = environment.api + 'public-language-content';

    public activeKiga: LanguageContent;
    public activePost: LanguageContent;

    private kigaLoadedSubject = new Subject();
    public kigaLoaded = this.kigaLoadedSubject.asObservable();

    constructor(private http: HttpClient, private pushNotificationService: PushNotificationService) {
        const activeKigaStr = localStorage.getItem('activeKiga');
        if (activeKigaStr) {
            this.activeKiga = JSON.parse(activeKigaStr);
        }
    }

    public findById(id: string, setKiga: boolean): Observable<LanguageContent> {
        return this.http.get<LanguageContent>(PublicLanguageContentService.BASE_URL + '/' + id).pipe(
            tap((res: LanguageContent) => {
                if (setKiga) {
                    this.setActiveKiga(res);
                }
            }),
        );
    }

    public findBySlug(slug: string, post = false, setRegion = false): Observable<LanguageContent> {
        return this.http.get<LanguageContent>(PublicLanguageContentService.BASE_URL + '/slug/' + slug).pipe(
            tap((res: LanguageContent) => {
                if (post) {
                    this.activePost = res;
                } else {
                    if (setRegion) {
                        this.setActiveKiga(res);
                    }
                }
            }),
        );
    }

    public findAllKommunen(): Observable<LanguageContent[]> {
        return this.http.get<LanguageContent[]>(
            PublicLanguageContentService.BASE_URL + '/template/' + environment.communeTemplateId,
        );
    }

    public findAllPostCategories(): Observable<LanguageContent[]> {
        return this.http.get<LanguageContent[]>(
            PublicLanguageContentService.BASE_URL + '/template/' + environment.postCategoryTemplateId,
        );
    }

    public findAllKigasOfKommune(regionId: string): Observable<LanguageContent[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('parentId', String(regionId));
        return this.http.get<LanguageContent[]>(
            PublicLanguageContentService.BASE_URL + '/template-child/' + environment.kigaTemplateId,
            {
                params,
            },
        );
    }

    public findAllFromTemplateAndParents(
        parents: string[],
        templateId: string,
        skip: number,
        limit: number,
        sortKey: string,
    ): Observable<LanguageContent[]> {
        let params: HttpParams = new HttpParams();
        params = params.append('languageKey', 'de');
        params = params.append('skip', String(skip));
        params = params.append('limit', String(limit));
        params = params.append('sortKey', sortKey);
        params = params.append('parents', JSON.stringify(parents));
        return this.http.get<LanguageContent[]>(
            `${PublicLanguageContentService.BASE_URL}/template-and-parents/${templateId}`,
            {
                params,
            },
        );
    }

    public getCountFromParent(region: LanguageContent): Observable<number> {
        let params: HttpParams = new HttpParams();
        params = params.append('languageKey', 'de');
        const parentContent = region.content as Content;
        return this.http.get<number>(PublicLanguageContentService.BASE_URL + '/' + parentContent._id + '/child/count', {
            params,
        });
    }

    public setActiveKiga(languageContent: LanguageContent): void {
        this.activeKiga = languageContent;
        this.kigaLoadedSubject.next();
        localStorage.setItem('activeKiga', JSON.stringify(this.activeKiga));
        localStorage.setItem('hideWelcomeBox', String(false));
        if (this.activeKiga) {
            const parentContent = this.activeKiga.content as Content;
            this.pushNotificationService.registerClient(parentContent._id);
        } else {
            this.pushNotificationService.removeToken();
        }
    }
}
