import { Component, OnInit, OnDestroy, Optional } from '@angular/core';
import { PublicLanguageContentService } from 'src/app/services/public-language-content.service';
import { LanguageContent } from 'src/app/models/language-content.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { PageLifecycleService } from 'src/app/services/page-lifecycle.service';
import { Subscription } from 'rxjs';
import { RefreshNotifyService } from 'src/app/services/refresh-notify.service';
import { AppComponent } from '../../app.component';
import { Content } from '../../models/content.interface';

@Component({
    selector: 'app-kiga-select',
    templateUrl: './kiga-select.component.html',
    styleUrls: ['./kiga-select.component.scss'],
})
export class KigaSelectComponent implements OnInit, OnDestroy {
    public regionSlug: string;
    public regionName: string;

    public kigas: LanguageContent[] = [];
    public filteredKigas: LanguageContent[] = [];

    private refreshSub: Subscription;
    private refreshNotifySub: Subscription;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly pageLifeCycleService: PageLifecycleService,
        private readonly refreshNotifyService: RefreshNotifyService,
        private readonly publicLanguageContentService: PublicLanguageContentService,
        @Optional() private appComponent: AppComponent,
    ) {
    }

    ngOnInit(): void {
        this.regionSlug = this.route.snapshot.paramMap.get('slug');

        this.getData();

        this.refreshSub = this.pageLifeCycleService.refresh.subscribe(() => {
            this.getData();
        });
        this.refreshNotifySub = this.refreshNotifyService.startLoad$.subscribe(() => {
            this.getData();
        });
    }

    ngOnDestroy(): void {
        this.refreshSub.unsubscribe();
        this.refreshNotifySub.unsubscribe();
    }

    public getData(): void {
        this.publicLanguageContentService.findBySlug(this.regionSlug).subscribe((region: LanguageContent) => {
            this.regionName = region.tabs[0].texts.find(text => text.textKey === 'name').text as string;
            this.publicLanguageContentService.findAllKigasOfKommune((region.content as Content)._id).subscribe((res: LanguageContent[]) => {
                this.kigas = res;
                this.filter('');
                this.refreshNotifyService.end();
            });
        });
    }

    public filter(value: string): void {
        if (value.length > 0) {
            this.filteredKigas = this.kigas.filter(this.isKigaShowingForUser);

            this.filteredKigas = this.filteredKigas.filter(region =>
                String(region.tabs[0].texts.find(text => text.textKey === 'name').text).match(
                    new RegExp(`.*${value}.*`, 'i'),
                ),
            );
        } else {
            this.filteredKigas = this.kigas.filter(this.isKigaShowingForUser);
        }
    }

    private isKigaShowingForUser(region: LanguageContent): boolean {
        const showForUsers = region.tabs[0].texts.find(text => text.textKey === 'showForUsers');

        if (!showForUsers) {
            return false;
        }

        return Boolean(showForUsers.text);
    }
}
