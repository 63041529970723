import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { PushNotificationService } from './services/push-notification.service';
import { Subscription } from 'rxjs';
import { S3File } from './models/s3-file.interface';
import { AngularFireAnalytics } from '@angular/fire/analytics';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    @ViewChild('outletContainer') outletContainer: ElementRef;

    private bodyElement;

    public headerVisible = false;

    constructor(private readonly pushNotificationService: PushNotificationService, private router: Router) {
    }

    ngOnInit(): void {
        this.bodyElement = document.getElementsByTagName('body')[0];

        this.pushNotificationService.requestPermission().subscribe();
        this.pushNotificationService.receiveMessage().subscribe();

        this.router.events.subscribe((event: RouterEvent) => {
            if (event.url) {
                if (event.url.includes('/beitrag/')) {
                    this.bodyElement.scrollTop = 0;
                    this.headerVisible = false;
                } else {
                    this.headerVisible = true;
                }
            }
        });
    }
}
