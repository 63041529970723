<div id="region-search-bar">
    <div id="info-container">
        <h2>In welchen Kindergarten geht ihr?</h2>
        <p>Sucht hier euren Kindergarten oder eure Kita aus!</p>
    </div>

    <div id="search-container">
        <mat-form-field appearance="outline" fxFlexFill class="small-input">
            <mat-label>Kindergarten eingeben</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput type="text" (keyup)="search.emit($event.target.value)" />
        </mat-form-field>
    </div>
</div>
