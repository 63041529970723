import { Component, OnInit, Input } from '@angular/core';
import { LanguageContent } from 'src/app/models/language-content.interface';
import { S3File } from '../../models/s3-file.interface';

@Component({
    selector: 'app-kiga-card',
    templateUrl: './kiga-card.component.html',
    styleUrls: ['./kiga-card.component.scss'],
})
export class KigaCardComponent implements OnInit {
    @Input() kiga: LanguageContent;

    public name: string;
    public file: S3File;

    constructor() {}

    ngOnInit(): void {
        this.name = this.kiga.tabs[0].texts.find(text => text.textKey === 'name').text as string;

        this.file = this.kiga.tabs[0].files.find(text => text.fileKey === 'logo').file as S3File;
    }
}
