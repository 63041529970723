import { Component, OnInit, OnDestroy, Optional } from '@angular/core';
import { PublicLanguageContentService } from 'src/app/services/public-language-content.service';
import { LanguageContent } from 'src/app/models/language-content.interface';
import { Router } from '@angular/router';
import { PageLifecycleService } from 'src/app/services/page-lifecycle.service';
import { Subscription } from 'rxjs';
import { RefreshNotifyService } from 'src/app/services/refresh-notify.service';
import { AppComponent } from '../../app.component';
import { Content } from '../../models/content.interface';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
    public regions: LanguageContent[] = [];
    public filteredRegions: LanguageContent[] = [];

    private refreshSub: Subscription;
    private refreshNotifySub: Subscription;

    private checkedRegions = 0;

    constructor(
        private readonly router: Router,
        private readonly pageLifeCycleService: PageLifecycleService,
        private readonly refreshNotifyService: RefreshNotifyService,
        private readonly publicLanguageContentService: PublicLanguageContentService,
        @Optional() private appComponent: AppComponent,
    ) {
    }

    ngOnInit(): void {
        this.getData();

        this.refreshSub = this.pageLifeCycleService.refresh.subscribe(() => {
            this.getData();
        });
        this.refreshNotifySub = this.refreshNotifyService.startLoad$.subscribe(() => {
            this.getData();
        });
    }

    ngOnDestroy(): void {
        this.refreshSub.unsubscribe();
        this.refreshNotifySub.unsubscribe();
    }

    public getData(): void {
        this.publicLanguageContentService.findAllKommunen().subscribe((res: LanguageContent[]) => {
            this.checkedRegions = 0;
            this.regions = [];

            for (const region of res) {
                this.publicLanguageContentService.findAllKigasOfKommune((region.content as Content)._id).subscribe((res2: LanguageContent[]) => {
                    if (res2.length > 0) {
                        this.regions.push(region);
                    }
                    this.checkedRegions++;

                    if (this.checkedRegions >= res.length) {
                        this.getDataFinish();
                    }
                });
            }
        });
    }

    public getDataFinish() {
        this.filter('');
        this.refreshNotifyService.end();
    }

    /*public setActiveRegion(region: LanguageContent): void {
        this.publicLanguageContentService.setActiveKiga(region);
        const slug = region.slug ? region.slug : '';
        this.router.navigate(['/kommune', slug]);
    }*/

    public filter(value: string): void {
        if (value.length > 0) {
            this.filteredRegions = this.regions.filter(this.isRegionShowingForUsers);

            this.filteredRegions = this.filteredRegions.filter(region =>
                String(region.tabs[0].texts.find(text => text.textKey === 'name').text).match(
                    new RegExp(`.*${value}.*`, 'i'),
                ),
            );
        } else {
            this.filteredRegions = this.regions.filter(this.isRegionShowingForUsers);
        }
    }

    private isRegionShowingForUsers(region: LanguageContent): boolean {
        const showForUsers = region.tabs[0].texts.find(text => text.textKey === 'showForUsers');

        if (!showForUsers) {
            return false;
        }

        return Boolean(showForUsers.text);
    }
}
