import { AddLikeDto } from '../dtos/add-like.dto';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { PushNotificationService } from './push-notification.service';

@Injectable({
    providedIn: 'root',
})
export class LikeService {
    private static readonly BASE_URL = environment.api + 'fcm-kiganews';

    constructor(private http: HttpClient) {
    }

    public addLike(postId: string, add: boolean): void {
        const dto = new AddLikeDto();
        dto.add = add;
        this.http.post(LikeService.BASE_URL + '/add-like/' + postId, dto).subscribe();
    }
}
