<div id="region-search-bar">
    <div id="info-container">
        <h2>Verrate uns, wo euer Kindergarten ist!</h2>
        <p>Wählt zunächst eure Stadt aus:</p>
    </div>

    <div id="search-container">
        <mat-form-field appearance="outline" fxFlexFill class="small-input">
            <mat-label>Stadt eingeben</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input matInput type="text" (keyup)="search.emit($event.target.value)" />
        </mat-form-field>
    </div>
</div>
