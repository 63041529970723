<app-pull-to-refresh></app-pull-to-refresh>
<div *ngIf="kiga" style="height: 100%">
    <div id="region-header" *ngIf="kiga.tabs[0].files.length > 0">
        <div class="image">
            <app-img-loader *ngIf="image" [file]="image" [fitMode]="'fitBoth'" [preferredSize]="'xl'"></app-img-loader>
        </div>
    </div>

    <div class="content-container">
        <div class="info-container" *ngIf="infoTitle && !infoHidden">
            <button mat-icon-button class="button-close" (click)="hideWelcomeBox()">
                <mat-icon inline="true">clear</mat-icon>
            </button>

            <h2 [innerHTML]="infoTitle"></h2>
            <p [innerHTML]="infoContent"></p>
        </div>

        <div class="posts-container">
            <div *ngFor="let post of limitedPosts">
                <app-post-card (click)="setActivePost(post)" [kigaSlug]="slug" [post]="post" [defaultImage]="image" [postCategories]="postCategories"></app-post-card>
            </div>
        </div>

        <div id="button-container">
            <button mat-flat-button class="hero-button" color="primary" *ngIf="limit < postCount" (click)="loadMore()">Mehr Meldungen anzeigen</button>
        </div>
    </div>
</div>
