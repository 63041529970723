import { Component, OnInit } from '@angular/core';
import { fromEvent, defer, timer, Observable, concat, merge } from 'rxjs';
import { map, switchMap, tap, takeUntil, take, repeat, startWith, filter, takeWhile } from 'rxjs/operators';
import { RefreshNotifyService } from '../../services/refresh-notify.service';

@Component({
    selector: 'app-pull-to-refresh',
    templateUrl: './pull-to-refresh.component.html',
    styleUrls: ['./pull-to-refresh.component.scss'],
})
export class PullToRefreshComponent implements OnInit {
    private currentPos = 0;

    private readonly pullDistance = window.innerHeight / 2.5;
    private readonly touchstart$ = fromEvent<TouchEvent>(document, 'touchstart');
    private readonly touchend$ = fromEvent<TouchEvent>(document, 'touchend');
    private readonly touchmove$ = fromEvent<TouchEvent>(document, 'touchmove');

    private completeAnimation$: Observable<number> = this.refreshNotifyService.endLoad$.pipe(
        map(() => this.currentPos),
        switchMap(currentPos => this.tweenObservable(currentPos, 0, 200)),
    );

    private returnPosition$ = timer(0, 10).pipe(take(20));

    private drag$ = this.touchstart$.pipe(
        switchMap(start => {
            let pos = 0;

            return concat(
                this.touchmove$.pipe(
                    map(move => move.touches[0].pageY - start.touches[0].pageY),
                    tap(p => (pos = p)),
                    filter(p => p < this.pullDistance),
                    takeUntil(this.touchend$),
                ),
                defer(() => this.tweenObservable(pos, 0, 200)),
            );
        }),
        takeWhile(p => p < this.pullDistance),
        repeat(),
    );

    private position$: Observable<number> = merge(this.completeAnimation$, this.drag$).pipe(
        startWith(0),
        tap((pos: number) => (this.currentPos = pos)),
    );

    public positionTranslate3d$: Observable<string> = this.position$.pipe(map(p => `translate3d(0, ${p - 70}px, 0)`));

    private rotate$ = this.refreshNotifyService.startLoad$.pipe(
        switchMap(() => {
            let rot = 0;
            return concat(
                this.tweenObservable(0, 360, 500).pipe(
                    repeat(),
                    tap((r: number) => (rot = r)),
                    takeUntil(this.refreshNotifyService.endLoad$),
                ),
                defer(() => this.tweenObservable(rot, 360, 360 - rot)),
            );
        }),
    );

    public positionRotate$: Observable<string> = this.rotate$.pipe(map(r => `rotate(${r}deg)`));

    public opacity$: Observable<number> = this.position$.pipe(map(p => p / this.pullDistance));

    constructor(private refreshNotifyService: RefreshNotifyService) {}

    ngOnInit(): void {
        this.touchstart$
            .pipe(
                switchMap(start => {
                    return this.touchend$.pipe(map(x => x.changedTouches[0].pageY - start.touches[0].pageY));
                }),
                filter(p => p >= this.pullDistance),
            )
            .subscribe(() => this.refreshNotifyService.start());
    }

    private tweenObservable(start, end, time) {
        const emissions = time / 10;
        const step = (start - end) / emissions;

        return timer(0, 10).pipe(
            map(x => start - step * (x + 1)),
            take(emissions),
        );
    }
}
