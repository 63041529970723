import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

export interface LoaderState {
    show: boolean;
}

@Injectable({
    providedIn: 'root'
})

export class LoaderService {

    private activeRequests = 0;
    private loaderSubject = new Subject<LoaderState>();
    public loaderState = this.loaderSubject.asObservable();

    constructor() { }

    public show(): void {
        setTimeout(() => {
            this.activeRequests++;
            this.loaderSubject.next({show: true} as LoaderState);
        }, 0);
    }

    public hide(): void {
        setTimeout(() => {
            this.activeRequests--;
            if (this.activeRequests < 1) {
                this.loaderSubject.next({show: false} as LoaderState);
            }
        }, 0);
    }

}
