<div
    class="card-content hover-enabled"
    matRipple
    [matRippleDisabled]="disableCardHover"
    (mouseenter)="cardHovering = true"
    (mouseleave)="cardHovering = false"
>
    <div>
        <div fxFlex="50" class="column-left">
            <app-img-loader
                *ngIf="picture"
                [borderRadius]="'6px'"
                [file]="picture"
                [fitMode]="'maxBoth'"
                [preferredSize]="'s'"
            ></app-img-loader>
            <app-img-loader
                *ngIf="!picture"
                [file]="defaultImage"
                [borderRadius]="'6px'"
                [fitMode]="'maxBoth'"
                [preferredSize]="'s'"
            ></app-img-loader>
        </div>
        <div fxFlex="50" class="column-right">
            <hr *ngIf="importantText" [ngStyle]="importantHrStyle" />

            <p class="post-type" *ngIf="importantText" [ngStyle]="importantStyle">{{ importantText }}</p>

            <p class="post-type post-category" *ngIf="categoryName">{{ categoryName }}</p>
            <p class="post-type post-category" *ngIf="!categoryName">Nachrichten</p>

            <h4>{{ title }}</h4>

            <div *ngIf="intro" class="intro">
                <p>{{ intro }}</p>
                <a>Mehr</a>
            </div>
        </div>
    </div>

    <div id="footer-container" fxLayout="row">
        <p class="post-date">{{ post.pubDate | dateToFullDateString }}</p>

        <div id="social-container" fxLayoutGap="10px">
            <button
                mat-flat-button
                (click)="$event.stopPropagation(); likePost()"
                [class.hover-enabled]="cardHovering"
                (mouseenter)="disableCardHover = true"
                (mouseleave)="disableCardHover = false"
            >
                <mat-icon id="icon-like" *ngIf="!hasLiked">favorite_border</mat-icon>
                <mat-icon id="icon-like" *ngIf="hasLiked">favorite</mat-icon>
                {{ likes }}
            </button>
            <!--<button mat-flat-button (click)="$event.stopPropagation(); sharePost()" [class.hover-enabled]="cardHovering" (mouseenter)="disableCardHover = true" (mouseleave)="disableCardHover = false">
                <mat-icon id="icon-share">share</mat-icon>
                Teilen
            </button>-->
        </div>
    </div>
</div>
