<div class="content-container">
    <div id="logo-container">
        <app-img-loader [file]="logo" [preferredSize]="'l'" [fitMode]="'fitBoth'"></app-img-loader>
    </div>

    <div id="info-container">
        <h2>...und zum Schluss bitte noch das Passwort eingeben!</h2>
        <p>Das Passwort bekommt ihr von eurer Einrichtung mitgeteilt.</p>
    </div>

    <div id="login-container">
        <form [formGroup]="form" (ngSubmit)="login()">
            <mat-form-field appearance="outline" fxFlexFill>
                <mat-label>Passwort</mat-label>
                <input
                    matInput
                    type="password"
                    formControlName="password"
                    [errorStateMatcher]="matcher"
                    [(ngModel)]="passwordInput"
                />
                <mat-error *ngIf="errorWrongPassword">Falsches Passwort</mat-error>
            </mat-form-field>

            <button mat-flat-button type="submit" color="primary" class="hero-button">Anmelden</button>
        </form>
    </div>
</div>
