import { Component, OnInit, Input } from '@angular/core';
import { LanguageContent } from 'src/app/models/language-content.interface';
import { S3File } from '../../../models/s3-file.interface';

@Component({
    selector: 'app-region-card',
    templateUrl: './region-card.component.html',
    styleUrls: ['./region-card.component.scss'],
})
export class RegionCardComponent implements OnInit {
    @Input() region: LanguageContent;

    public name: string;
    public file: S3File;

    constructor() {}

    ngOnInit(): void {
        this.name = this.region.tabs[0].texts.find(text => text.textKey === 'name').text as string;

        this.file = this.region.tabs[0].files.find(text => text.fileKey === 'logo').file as S3File;
    }
}
