export enum S3FileType {
    PICTURE = 'picture',
    VIDEO = 'video',
    DOCUMENT = 'document',
    ICON = 'icon',
    DOWNLOAD = 'download',
}

export interface S3File {
    _id: string;
    path: string;
    type: S3FileType;
    title: string;
    altTitle: string;
    tags: string[];
    author: string;
    description: string;
    contentType: string;
    largestWidth: number;
    url: string;
    company: string;
    createdAt: Date;
    updatedAt: Date;
}
