import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild } from '@angular/router';
import { PublicLanguageContentService } from '../services/public-language-content.service';

@Injectable({
    providedIn: 'root',
})

/**
 * @author Jan Kessler
 */
export class ActiveKigaGuard implements CanActivate, CanActivateChild {
    constructor(private readonly publicLanguageContentService: PublicLanguageContentService, private router: Router) {}

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.checkActiveRegion();
    }

    public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    private checkActiveRegion(): boolean {
        if (this.publicLanguageContentService.activeKiga) {
            return true;
        }

        this.router.navigate(['/home']);

        return false;
    }
}
