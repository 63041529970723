import { Component, OnInit, Input } from '@angular/core';
import { LanguageContent } from 'src/app/models/language-content.interface';
import { S3File } from 'src/app/models/s3-file.interface';
import { PushNotificationService } from '../../../services/push-notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LikeService } from '../../../services/like.service';
import { Content } from '../../../models/content.interface';

@Component({
    selector: 'app-post-card',
    templateUrl: './post-card.component.html',
    styleUrls: ['./post-card.component.scss'],
})
export class PostCardComponent implements OnInit {
    @Input() post: LanguageContent;
    @Input() kigaSlug: string;
    @Input() postCategories: LanguageContent[];
    @Input() defaultImage: S3File;

    public picture: S3File;
    public title: string;
    public intro: string;
    public importantText: string;
    public importantColor: string;
    public slug: string;

    public categoryName: string;

    public likes: number = 0;
    public hasLiked: boolean = false;

    public disableCardHover = false;
    public cardHovering = false;

    public get importantStyle(): any {
        if (this.importantText) {
            const color = this.importantColor ? this.importantColor : '#FF7F0F';
            return { color: color };
        }

        return '';
    }

    public get importantHrStyle(): any {
        if (this.importantText) {
            const color = this.importantColor ? this.importantColor : '#FF7F0F';
            return { 'border-color': color };
        }

        return '';
    }

    constructor(private readonly pushNotificationService: PushNotificationService,
                private readonly snackbar: MatSnackBar,
                private readonly likeService: LikeService) {
    }

    ngOnInit(): void {
        const tab = this.post.tabs[0];
        if (tab.files.length > 0) {
            const picData = tab.files.find(file => file.fileKey === 'bild');
            if (picData) {
                this.picture = picData.file;
            }
        }
        this.title = tab.texts.find(text => text.textKey === 'titel').text as string;
        this.intro = tab.texts.find(text => text.textKey === 'intro').text as string;

        if (this.intro && this.intro.length > 250) {
            this.intro = this.intro.substr(0, 250);
        }

        if (this.intro && this.intro.length > 2) {
            const end = this.intro.substr(this.intro.length - 3);
            if (end !== '...') {
                this.intro = this.intro + '...';
            }
        }

        const importantTextData = tab.texts.find(text => text.textKey === 'important-text');
        const importantColorData = tab.texts.find(text => text.textKey === 'important-color');
        if (importantTextData) {
            this.importantText = importantTextData.text as string;
        }
        if (importantColorData) {
            this.importantColor = importantColorData.text as string;
        }

        const likesData = tab.texts.find(text => text.textKey === 'likes');
        if (likesData) {
            this.likes = likesData.text as number;
        }
        if (!this.likes) {
            this.likes = 0;
        }

        const likedPosts: string[] = JSON.parse(localStorage.getItem('likedPosts'));
        if (likedPosts) {
            this.hasLiked = likedPosts.includes(this.post._id);
        }

        this.slug = this.post.slug;

        this.categoryName = '';
        for (const parent of this.post.contentParents) {
            for (const postCategory of this.postCategories) {
                if (parent === (postCategory.content as unknown as Content)._id) {
                    this.categoryName = postCategory.tabs[0].texts[0].text as string;
                    break;
                }
            }
        }
    }

    public likePost() {
        this.hasLiked = !this.hasLiked;

        this.likeService.addLike(this.post._id, this.hasLiked);

        let likedPosts: string[] = JSON.parse(localStorage.getItem('likedPosts'));
        if (!likedPosts) {
            likedPosts = [];
        }

        if (!this.hasLiked) {
            const index = likedPosts.indexOf(this.post._id);
            if (index >= 0) {
                likedPosts.splice(index, 1);
            }

            this.likes--;
            this.likes = Math.max(this.likes, 0);
        } else {
            if (!likedPosts.includes(this.post._id)) {
                likedPosts.push(this.post._id);
            }

            this.likes++;
        }

        localStorage.setItem('likedPosts', JSON.stringify(likedPosts));
    }

    public async sharePost() {
        const url = 'https://kiganews.de/kiga/' + this.kigaSlug + '/beitrag/' + this.slug;

        const navigator: any = window.navigator;

        if (navigator.share) {
            navigator.share({
                title: this.title,
                text: this.intro,
                url: url,
            })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            await navigator.clipboard.writeText(url);
            this.snackbar.open('Link in die Zwischenablage kopiert', '', { duration: 2500 });
        }
    }
}
