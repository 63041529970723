import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-allow-cookies',
    templateUrl: './allow-cookies.component.html',
    styleUrls: ['./allow-cookies.component.scss'],
})
export class AllowCookiesComponent implements OnInit {
    public showNotification = false;

    constructor() {}

    ngOnInit(): void {
        const acceptCookies = localStorage.getItem('acceptCookies');
        setTimeout(() => {
            this.showNotification = acceptCookies ? false : true;
        }, 2000);
    }

    public onAcceptCookies(): void {
        this.showNotification = false;
        localStorage.setItem('acceptCookies', new Date().toDateString());
    }
}
