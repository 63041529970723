import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { S3File, S3FileType } from '../../models/s3-file.interface';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-img-loader',
    templateUrl: './img-loader.component.html',
    styleUrls: ['./img-loader.component.scss'],
})
export class ImgLoaderComponent implements OnInit, OnChanges {
    private PICTURE_WIDTHS: number[] = [150, 400, 550, 800, 1000, 1920];
    private PICTURE_WIDTH_SUFFIXES: string[] = ['xs', 's', 'm', 'l', 'xl', 'xxl'];

    @Input() file: S3File;
    @Input() useDevicePixelRatio = false;
    @Input() preferredSize = 'm';
    @Input() borderRadius = '0';

    @Input() fitMode: 'none' | 'fitWidth' | 'fitHeight' | 'fitBoth' | 'maxWidth' | 'maxHeight' | 'maxBoth';

    public imageUrl = '';
    private typeSuffix = '';

    constructor(private domSanitizer: DomSanitizer) {
    }

    ngOnInit(): void {
        this.updateImageUrl();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateImageUrl();
    }

    private canUseWebP() {
        const elem = document.createElement('canvas');

        if (!!(elem.getContext && elem.getContext('2d'))) {
            // was able or not to get WebP representation
            return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
        }

        // very old browser like IE 8, canvas not supported
        return false;
    }

    public updateImageUrl() {
        if (!this.file) {
            return;
        }

        if (this.file.type !== S3FileType.PICTURE) {
            this.imageUrl = this.file.url;
            return;
        }

        this.typeSuffix = 'png';
        if (this.canUseWebP()) {
            this.typeSuffix = 'webp';
        }

        if (this.file.contentType === 'image/jpeg') {
            this.typeSuffix = 'jpeg';
        }

        let displayWidth = 0;
        if (this.PICTURE_WIDTH_SUFFIXES.includes(this.preferredSize)) {
            while (this.preferredSize !== this.PICTURE_WIDTH_SUFFIXES[displayWidth]) {
                if (this.PICTURE_WIDTHS.length - 1 > displayWidth) {
                    if (this.file.largestWidth > this.PICTURE_WIDTHS[displayWidth]) {
                        displayWidth++;
                    } else {
                        break;
                    }
                } else {
                    break;
                }
            }
        }

        this.imageUrl = this.file.url + '.' + this.typeSuffix + '_' + this.PICTURE_WIDTH_SUFFIXES[displayWidth];
    }

    /*public updateImageUrl() {
        if (this.file.type !== S3FileType.PICTURE) {
            this.imageUrl = this.file.url;
            return;
        }

        if (!this.imgElement.nativeElement.clientWidth) {
            this.imageUrl = this.file.url + '.' + this.typeSuffix + '_' + this.PICTURE_WIDTH_SUFFIXES[0];
            return;
        }

        let actualSize = this.imgElement.nativeElement.clientWidth;
        if (this.useDevicePixelRatio) {
            // This calculates the real size of the image on the display in pixels
            // (retina, 4K, etc. have devicePixelRatios of 2 and higher)
            actualSize *= window.devicePixelRatio;
        }

        let displayWidth = 0;
        while (actualSize > this.PICTURE_WIDTHS[displayWidth]) {
            if (this.PICTURE_WIDTHS.length - 1 > displayWidth) {
                if (this.file.largestWidth > this.PICTURE_WIDTHS[displayWidth]) {
                    displayWidth++;
                } else {
                    break;
                }
            } else {
                break;
            }
        }

        const widthSuffix = this.PICTURE_WIDTH_SUFFIXES[displayWidth];
        this.imageUrl = this.file.url + '.' + this.typeSuffix + '_' + widthSuffix;
    }*/
}
