export const environment = {
    communeTemplateId: '5e747e6b885676b9c8eba0e2',
    kigaTemplateId: '5eabddbd2be7976aa872b044',
    postTemplateId: '5e747f19885676b9c8eba0e7',
    postCategoryTemplateId: '5ec296d048b96c144e485197',
    imprintTabId: '5e74e132eb9febc75bc38f2a',
    contactTabId: '5e7b6c0005dcce2377e6204c',
    installationGuideLanguageId: '5e74e1ddeb9febc75bc38f35',
    privacyTabId: '5e74e16eeb9febc75bc38f31',
    production: true,
    api: 'https://kiganews.de/api/',
    firebase: {
        apiKey: 'AIzaSyBHFoqY0jpn5HPsHs5JL4sgdLOYjmnp0SE',
        authDomain: 'famigo-corona.firebaseapp.com',
        databaseURL: 'https://famigo-corona.firebaseio.com',
        projectId: 'famigo-corona',
        storageBucket: 'famigo-corona.appspot.com',
        messagingSenderId: '670808462578',
        appId: '1:670808462578:web:ca1a5f06657a0aff9fac6b',
        measurementId: 'G-E2FLB8J4X4',
    },
};
