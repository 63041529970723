import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { RegisterClientDto } from '../dtos/register-client.dto';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AddLikeDto } from '../dtos/add-like.dto';

@Injectable({
    providedIn: 'root',
})
export class PushNotificationService {
    private static readonly BASE_URL = environment.api + 'fcm-kiganews';

    private token: string;

    constructor(private http: HttpClient, private angularFireMessaging: AngularFireMessaging) {
        this.angularFireMessaging.messaging.subscribe((messagingContext) => {
            messagingContext.onMessage = messagingContext.onMessage.bind(messagingContext);
            messagingContext.onTokenRefresh = messagingContext.onTokenRefresh.bind(messagingContext);
        });
    }
    public requestPermission(): Observable<string> {
        return this.angularFireMessaging.requestToken.pipe(
            tap((res: string) => {
                this.token = res;
            }),
        );
    }

    public removeToken(): Observable<boolean> {
        return this.angularFireMessaging.deleteToken(this.token);
    }

    public receiveMessage(): Observable<any> {
        return this.angularFireMessaging.messages;
    }

    public registerClient(id: string): void {
        if (this.token) {
            const dto = new RegisterClientDto();
            dto.unsubTopics = true;
            dto.clientId = this.token;
            this.http.post(PushNotificationService.BASE_URL + '/' + id, dto).subscribe();
        }
    }
}
