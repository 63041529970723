import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-kiga-search',
    templateUrl: './kiga-search.component.html',
    styleUrls: ['./kiga-search.component.scss'],
})
export class KigaSearchComponent implements OnInit {
    @Input() regionName: string;

    @Output() search = new EventEmitter<string>();

    constructor() {
    }

    ngOnInit(): void {
    }
}
