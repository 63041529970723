import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class RefreshNotifyService {
    private readonly startSubject = new Subject<any>();
    private readonly endSubject = new Subject<any>();

    public get startLoad$(): Observable<any> {
        return this.startSubject.asObservable();
    }

    public get endLoad$(): Observable<any> {
        return this.endSubject.asObservable();
    }

    public start(): void {
        this.startSubject.next();
    }

    public end(): void {
        this.endSubject.next();
    }
}
