import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { PostOverviewComponent } from './pages/post-overview/post-overview.component';
import { PostDetailComponent } from './pages/post-detail/post-detail.component';
import { ActiveKigaGuard } from './guards/active-kiga-guard';
import { InstallationGuideComponent } from './pages/installation-guide/installation-guide.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ActivePostGuard } from './guards/active-post.guard';
import { ContactComponent } from './pages/contact/contact.component';
import { KigaSelectComponent } from './pages/kiga-select/kiga-select.component';
import { KigaLoginComponent } from './pages/kiga-login/kiga-login.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: 'kiga' },
    { path: 'home', component: HomeComponent },
    { path: 'region/:slug', component: KigaSelectComponent, canActivate: [] },
    { path: 'kiga-login/:slug', component: KigaLoginComponent, canActivate: [] },
    { path: 'kiga', component: PostOverviewComponent, canActivate: [ActiveKigaGuard] },
    { path: 'kiga/:slug', component: PostOverviewComponent },
    { path: 'kiga/:slug/beitrag', component: PostDetailComponent, canActivate: [ActiveKigaGuard, ActivePostGuard] },
    { path: 'kiga/:slug/beitrag/:postslug', component: PostDetailComponent, canActivate: [ActiveKigaGuard] },
    { path: 'installationsanleitung', component: InstallationGuideComponent },
    { path: 'kontakt', component: ContactComponent },
    { path: 'impressum', component: ImprintComponent },
    { path: 'datenschutz', component: PrivacyComponent },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
