import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, Optional, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicLanguageContentService } from 'src/app/services/public-language-content.service';
import { LanguageContent } from 'src/app/models/language-content.interface';
import { S3File } from 'src/app/models/s3-file.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AppComponent } from '../../app.component';
import { Content } from '../../models/content.interface';
import { PublicLanguageContentTabsService } from '../../services/public-language-content-tabs.service ';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { PushNotificationService } from '../../services/push-notification.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LikeService } from '../../services/like.service';

@Component({
    selector: 'app-post-detail',
    templateUrl: './post-detail.component.html',
    styleUrls: ['./post-detail.component.scss'],
})
export class PostDetailComponent implements OnInit {
    public ready = false;
    public picture: S3File;
    public title: string;
    public intro: string;
    public content: string;

    public attachmentTitle: string;
    public attachmentFiles: S3File[];

    public kiga: LanguageContent;
    public kigaLogo: S3File;

    public youtubeEmbedSrc: string;
    public likes: number = 0;

    private kigaSlug: string;
    private slug: string;

    public hasLiked: boolean = false;

    public sanitizedYoutubeEmbedLink: SafeResourceUrl;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly publicLanguageContentService: PublicLanguageContentService,
        private readonly publicLanguageContentTabsService: PublicLanguageContentTabsService,
        private readonly domSanitizer: DomSanitizer,
        private readonly pushNotificationService: PushNotificationService,
        @Optional() private appComponent: AppComponent,
        private readonly angularFireAnalytics: AngularFireAnalytics,
        private readonly snackbar: MatSnackBar,
        private readonly likeService: LikeService,
    ) {}

    ngOnInit(): void {
        this.kigaSlug = this.route.snapshot.paramMap.get('slug');
        this.slug = this.route.snapshot.paramMap.get('postslug');

        if (this.kigaSlug && this.slug) {
            this.angularFireAnalytics.logEvent('kiga/' + this.kigaSlug + '/beitrag/' + this.slug);

            this.publicLanguageContentService.findBySlug(this.slug, true).subscribe(
                () => {
                    this.setData();
                },
                () => {
                    this.router.navigate(['/']);
                },
            );

            this.publicLanguageContentService.findBySlug(this.kigaSlug).subscribe((res) => {
                this.kiga = res;
                const logoData = this.kiga.tabs[0].files.find((file) => file.fileKey === 'logo');
                if (logoData) {
                    this.kigaLogo = logoData.file;
                }
            });
        } else {
            this.setData();
        }
    }

    public get introSanitized(): SafeResourceUrl {
        return this.intro ? this.intro : '';
        // return this.domSanitizer.bypassSecurityTrustHtml(this.content);
    }

    public get contentSanitized(): SafeResourceUrl {
        return this.content ? this.content : '';
        // return this.domSanitizer.bypassSecurityTrustHtml(this.content);
    }

    public get attachmentTitleSanitized(): SafeResourceUrl {
        return this.attachmentTitle ? this.attachmentTitle : '';
        // return this.domSanitizer.bypassSecurityTrustHtml(this.content);
    }

    public get post(): LanguageContent {
        return this.publicLanguageContentService.activePost;
    }

    public likePost() {
        this.hasLiked = !this.hasLiked;

        this.likeService.addLike(this.post._id, this.hasLiked);

        let likedPosts: string[] = JSON.parse(localStorage.getItem('likedPosts'));
        if (!likedPosts) {
            likedPosts = [];
        }

        if (!this.hasLiked) {
            const index = likedPosts.indexOf(this.post._id);
            if (index >= 0) {
                likedPosts.splice(index, 1);
            }

            this.likes--;
            this.likes = Math.max(this.likes, 0);
        } else {
            if (!likedPosts.includes(this.post._id)) {
                likedPosts.push(this.post._id);
            }

            this.likes++;
        }

        localStorage.setItem('likedPosts', JSON.stringify(likedPosts));
    }

    public async sharePost() {
        const url = 'https://kiganews.de/kiga/' + this.kigaSlug + '/beitrag/' + this.slug;

        const navigator: any = window.navigator;

        if (navigator.share) {
            navigator
                .share({
                    title: this.title,
                    text: this.intro,
                    url: url,
                })
                .then(() => console.log('Successful share'))
                .catch((error) => console.log('Error sharing', error));
        } else {
            await navigator.clipboard.writeText(url);
            this.snackbar.open('Link in die Zwischenablage kopiert', '', { duration: 2500 });
        }
    }

    public setData(): void {
        const tab = this.post.tabs[0];
        if (tab.files.length > 0) {
            const picData = tab.files.find((file) => file.fileKey === 'bild');
            if (picData) {
                this.picture = picData.file;
            }
        }
        this.title = tab.texts.find((text) => text.textKey === 'titel').text as string;
        this.intro = tab.texts.find((text) => text.textKey === 'intro').text as string;
        this.content = tab.texts.find((text) => text.textKey === 'content').text as string;

        const attachmentTitleText = tab.texts.find((text) => text.textKey === 'attachment-title');
        if (attachmentTitleText) {
            this.attachmentTitle = attachmentTitleText.text as string;
        }
        const attachmentFileFiles = tab.files.filter(
            (file) => file && file.file && file.fileKey && file.fileKey.startsWith('attachment-file'),
        );
        if (attachmentFileFiles) {
            this.attachmentFiles = attachmentFileFiles.map((attachment) => attachment.file);
        }

        const youtubeEmbedData = tab.texts.find((text) => text.textKey === 'youtube-embed');
        if (youtubeEmbedData) {
            let link = youtubeEmbedData.text as string;

            if (link) {
                let videoID = '';
                if (link.includes('/embed/')) {
                    videoID = link.substr(link.indexOf('/embed/') + 7);
                } else if (link.includes('v=')) {
                    videoID = link.substr(link.indexOf('v=') + 2);

                    const andIndex = videoID.indexOf('&');
                    if (andIndex !== -1) {
                        videoID = videoID.substr(0, andIndex);
                    }
                } else if (link.length === 11) {
                    videoID = link;
                }

                if (videoID) {
                    link = 'https://www.youtube.com/embed/' + videoID;

                    if (
                        link &&
                        (link.startsWith('http://www.youtube.com/embed/') ||
                            link.startsWith('https://www.youtube.com/embed/'))
                    ) {
                        this.youtubeEmbedSrc = link;
                        this.sanitizedYoutubeEmbedLink = this.youtubeEmbedSrc
                            ? this.domSanitizer.bypassSecurityTrustResourceUrl(this.youtubeEmbedSrc)
                            : '';
                    }
                }
            }
        }

        const likesData = tab.texts.find((text) => text.textKey === 'likes');
        if (likesData) {
            this.likes = likesData.text as number;
        }
        if (!this.likes) {
            this.likes = 0;
        }

        if (!this.slug) {
            this.angularFireAnalytics.logEvent('beitrag/' + this.title);
        }

        /*let processedContent = '';
        if (this.content && this.content.length > 0) {
            const paragraphs = this.content.split('\n');
            for (const paragraph of paragraphs) {
                processedContent += '<p>' + paragraph + '</p>';
            }
        }*/
        if (this.content) {
            this.content = this.content.replace(/<a/g, '<a');
        }

        const likedPosts: string[] = JSON.parse(localStorage.getItem('likedPosts'));
        if (likedPosts) {
            this.hasLiked = likedPosts.includes(this.post._id);
        }

        this.ready = true;
    }

    public navigateBack(): void {
        const comingFromKiga = sessionStorage.getItem('comingFromKiga');
        this.router.navigate(['/kiga', comingFromKiga]);
    }
}
