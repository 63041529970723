import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export enum PageVisibility {
    VISIBLE = 'visible',
    INVISIBLE = 'invisible',
}

@Injectable({
    providedIn: 'root',
})
export class PageLifecycleService {
    private visibilitySubject = new Subject<PageVisibility>();
    public visibilityChange = this.visibilitySubject.asObservable();

    private resumeSubject = new Subject();
    public resume = this.resumeSubject.asObservable();

    private refreshSubject = new Subject();
    public refresh = this.refreshSubject.asObservable();

    constructor() {
        document.addEventListener('visibilitychange', () => {
            this.visibilitySubject.next(document.hidden ? PageVisibility.INVISIBLE : PageVisibility.VISIBLE);
            if (!document.hidden) {
                this.refreshSubject.next();
            }
        });
        document.addEventListener('resume', () => {
            this.resumeSubject.next();
            this.refreshSubject.next();
        });
    }
}
