import { Component, OnInit } from '@angular/core';
import { PublicLanguageContentTabsService } from '../../services/public-language-content-tabs.service ';
import { environment } from 'src/environments/environment';
import { LanguageContentTab } from '../../models/language-content-tab.interface';

@Component({
    selector: 'app-imprint',
    templateUrl: './imprint.component.html',
    styleUrls: ['./imprint.component.scss'],
})
export class ImprintComponent implements OnInit {

    public ready: boolean;

    public title: string;
    public content: string;

    constructor(private publicLanguageContentTabsService: PublicLanguageContentTabsService) {
    }

    ngOnInit(): void {
        this.publicLanguageContentTabsService.findById(environment.imprintTabId).subscribe((res: LanguageContentTab) => {
            this.title = res.texts.find(text => text.textKey === 'Titel').text as string;
            this.content = res.texts.find(text => text.textKey === 'Content').text as string;

            this.ready = true;
        });
    }

}
