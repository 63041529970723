import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDeCa from '@angular/common/locales/de';
import localeDeCaExtra from '@angular/common/locales/extra/de';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { CustomMaterialModule } from './material.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { NavbarComponent } from './elements/navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PullToRefreshComponent } from './elements/pull-to-refresh/pull-to-refresh.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { HomeComponent } from './pages/home/home.component';
import { RegionSearchComponent } from './pages/home/region-search/region-search.component';
import { RegionCardComponent } from './pages/home/region-card/region-card.component';
import { MatRippleModule } from '@angular/material/core';
import { PostOverviewComponent } from './pages/post-overview/post-overview.component';
import { PostCardComponent } from './pages/post-overview/post-card/post-card.component';
import { PostDetailComponent } from './pages/post-detail/post-detail.component';
import { DateToFullDateStringPipe } from './pipes/date-to-full-date.pipe';
import { ImgLoaderComponent } from './elements/img-loader/img-loader.component';
import { InstallationGuideComponent } from './pages/installation-guide/installation-guide.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { AllowCookiesComponent } from './elements/allow-cookies/allow-cookies.component';
import { FamigoLogoComponent } from './elements/famigo-logo/famigo-logo.component';
import { FooterComponent } from './elements/footer/footer.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AngularFireAnalytics, AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';
import { KigaCardComponent } from './elements/kiga-card/kiga-card.component';
import { KigaSelectComponent } from './pages/kiga-select/kiga-select.component';
import { KigaSearchComponent } from './pages/kiga-select/kiga-search/kiga-search.component';
import { KigaLoginComponent } from './pages/kiga-login/kiga-login.component';

registerLocaleData(localeDeCa, localeDeCaExtra);

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        PullToRefreshComponent,
        HomeComponent,
        RegionSearchComponent,
        RegionCardComponent,
        PostOverviewComponent,
        PostCardComponent,
        PostDetailComponent,
        DateToFullDateStringPipe,
        ImgLoaderComponent,
        InstallationGuideComponent,
        ImprintComponent,
        PrivacyComponent,
        AllowCookiesComponent,
        FamigoLogoComponent,
        FooterComponent,
        ContactComponent,
        KigaCardComponent,
        KigaSelectComponent,
        KigaSearchComponent,
        KigaLoginComponent
    ],
    imports: [
        FlexLayoutModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        CustomMaterialModule,
        FormsModule,
        ReactiveFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireMessagingModule,
        AngularFireAnalyticsModule,
        MatRippleModule,
    ],
    entryComponents: [],
    providers: [{ provide: LOCALE_ID, useValue: 'de' }, DateToFullDateStringPipe],
    bootstrap: [AppComponent],
})
export class AppModule {}
