<div class="card-content" matRipple>
    <div fxLayout="column" fxFlex="95">
        <div id="img-container">
            <app-img-loader [file]="file" [preferredSize]="'m'" [fitMode]="'fitHeight'"></app-img-loader>
        </div>
        <p class="primary-col">{{name}}</p>
    </div>


    <mat-icon id="arrow-right" color="primary" fxFlex="5">
        keyboard_arrow_right
    </mat-icon>
</div>
