import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LanguageContent } from '../models/language-content.interface';
import { LanguageContentTab } from '../models/language-content-tab.interface';

@Injectable({
    providedIn: 'root',
})
export class PublicLanguageContentTabsService {
    private static readonly BASE_URL = environment.api + 'public-language-content-tabs';

    public activeKiga: LanguageContent;

    constructor(private http: HttpClient) {
        const activeKigaStr = localStorage.getItem('parentContent');
        if (activeKigaStr) {
            this.activeKiga = JSON.parse('activeKiga');
        }
    }

    public findById(id: string): Observable<LanguageContentTab> {
        return this.http.get<LanguageContentTab>(PublicLanguageContentTabsService.BASE_URL + '/' + id);
    }
}
