<div class="ao-nav-bar">
    <div id="menu-container">
        <button mat-icon-button class="menu-trigger" (click)="toggleMenu()">
            <mat-icon *ngIf="!menuActive">menu</mat-icon>
            <mat-icon *ngIf="menuActive">close</mat-icon>
        </button>
    </div>

    <img src="assets/header-bubbles.svg" id="bubbles-svg" />
    <img src="assets/header-title.svg" alt="Kiga News" id="title-svg" [routerLink]="['/']" />

    <div id="loading-spinner" *ngIf="activeRequest">
        <mat-spinner color="accent" [diameter]="25"></mat-spinner>
    </div>

    <div class="menu" *ngIf="menuActive">
        <div id="circle-outer" class="accent-lighter-bg-col"></div>
        <div id="circle-inner" class="accent-bg-col"></div>

        <div id="content-container">
            <div id="nav-container" *ngIf="kiga">
                <div id="row-kiga-name">
                    <span id="kiga-name">Dein Kiga</span>
                    <button mat-flat-button (click)="unsubscribeFromKiga()" id="button-remove-kiga">
                        <mat-icon class="accent-col" inline>clear</mat-icon>
                        Löschen
                    </button>
                </div>

                <app-kiga-card
                    [kiga]="kiga"
                    [routerLink]="['/kiga/' + kiga.slug]"
                    (click)="menuActive = false"
                ></app-kiga-card>
            </div>
            <div id="nav-container" *ngIf="!kiga">
                <div id="row-kiga-name">
                    <span id="kiga-name">Kein Kiga ausgewählt</span>
                    <button mat-stroked-button [routerLink]="['/']" (click)="menuActive = false">Auswählen</button>
                </div>
            </div>

            <div id="famigo-container">
                <a class="nav-link" (click)="menuActive = false" routerLink="/impressum">Impressum</a>
                <a class="nav-link" (click)="menuActive = false" routerLink="/datenschutz">Datenschutzerklärung</a>

                <mat-divider></mat-divider>
                <app-famigo-logo></app-famigo-logo>
            </div>
        </div>
    </div>
</div>
