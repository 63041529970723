import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService, LoaderState } from '../../services/loader.service';
import { Location } from '@angular/common';
import { PublicLanguageContentService } from '../../services/public-language-content.service';
import { S3File } from '../../models/s3-file.interface';
import { LanguageContent } from '../../models/language-content.interface';
import { Router } from '@angular/router';
import { AppComponent } from '../../app.component';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    @Input() menuActive = false;

    @Output() openMenu = new EventEmitter();

    public activeRequest = false;

    private loaderSubscription: Subscription;

    public get kiga(): LanguageContent {
        return this.publicLanguageContentService.activeKiga;
    }

    constructor(
        private loaderService: LoaderService,
        private router: Router,
        private publicLanguageContentService: PublicLanguageContentService) {
    }

    ngOnInit() {
        this.loaderSubscription = this.loaderService.loaderState.subscribe((res: LoaderState) => {
            this.activeRequest = res.show;
        });
    }

    ngOnDestroy(): void {
        this.loaderSubscription.unsubscribe();
    }

    public toggleMenu() {
        this.menuActive = !this.menuActive;
    }

    public unsubscribeFromKiga() {
        this.publicLanguageContentService.setActiveKiga(null);
        this.menuActive = false;
        this.router.navigate(['/']);
    }
}
