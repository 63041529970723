import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'dateToFullDateString',
})
export class DateToFullDateStringPipe implements PipeTransform {
    transform(dateStr): any {
        const date = new Date(dateStr);

        return (
            this.addZero(date.getHours()) +
            ':' +
            this.addZero(date.getMinutes()) +
            ' - ' +
            this.addZero(date.getDate()) +
            '.' +
            this.addZero(date.getMonth() + 1) +
            '.' +
            date.getFullYear()
        );
    }

    private addZero(num: number): string {
        const strNum = String(num);
        return strNum.length === 1 ? '0' + strNum : strNum;
    }
}
