import { Component, OnInit } from '@angular/core';
import { PublicLanguageContentTabsService } from '../../services/public-language-content-tabs.service ';
import { environment } from '../../../environments/environment';
import { LanguageContentTab } from '../../models/language-content-tab.interface';
import { PublicLanguageContentService } from '../../services/public-language-content.service';
import { LanguageContent } from '../../models/language-content.interface';

@Component({
    selector: 'app-installation-guide',
    templateUrl: './installation-guide.component.html',
    styleUrls: ['./installation-guide.component.scss'],
})
export class InstallationGuideComponent implements OnInit {

    public ready: boolean;

    public tabs: LanguageContentTab[];
    public title: string;

    constructor(private publicLanguageContentService: PublicLanguageContentService) {
    }

    ngOnInit(): void {
        this.publicLanguageContentService.findById(environment.installationGuideLanguageId, false).subscribe((res: LanguageContent) => {
            this.title = res.tabs[0].texts.find(text => text.textKey === 'Titel').text as string;

            this.tabs = res.tabs.slice(1);

            this.ready = true;
        });
    }

    public getTitleOfTab(tab: LanguageContentTab) {
        return tab.texts.find(text => text.textKey === 'Titel').text as string;
    }
    public getContentOfTab(tab: LanguageContentTab) {
        return tab.texts.find(text => text.textKey === 'Anleitung').text as string;
    }
}
