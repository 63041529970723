import { Component, OnInit, OnDestroy, Optional } from '@angular/core';
import { PublicLanguageContentService } from 'src/app/services/public-language-content.service';
import { LanguageContent } from 'src/app/models/language-content.interface';
import { ActivatedRoute, Router } from '@angular/router';
import { PageLifecycleService } from 'src/app/services/page-lifecycle.service';
import { Subscription } from 'rxjs';
import { RefreshNotifyService } from 'src/app/services/refresh-notify.service';
import { AppComponent } from '../../app.component';
import { S3File } from '../../models/s3-file.interface';
import { FormControl, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
    selector: 'app-kiga-login',
    templateUrl: './kiga-login.component.html',
    styleUrls: ['./kiga-login.component.scss'],
})
export class KigaLoginComponent implements OnInit {
    public kigaSlug;
    public kiga: LanguageContent;

    public logo: S3File;
    public password: string;

    public passwordInput: string;

    public errorWrongPassword: boolean;

    public form: FormGroup;
    public matcher = new MyErrorStateMatcher();

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly publicLanguageContentService: PublicLanguageContentService,
        @Optional() private appComponent: AppComponent,
    ) {}

    ngOnInit(): void {
        this.form = new FormGroup({
            password: new FormControl('', []),
        });

        this.kigaSlug = this.route.snapshot.paramMap.get('slug');

        if (this.kigaSlug) {
            if (
                this.publicLanguageContentService.activeKiga &&
                this.publicLanguageContentService.activeKiga.slug === this.kigaSlug
            ) {
                this.router.navigate(['/kiga/' + this.kigaSlug]);
                return;
            }

            this.getData();
        } else {
            this.router.navigate(['/']);
        }
    }

    public getData(): void {
        this.publicLanguageContentService.findBySlug(this.kigaSlug).subscribe((res: LanguageContent) => {
            this.kiga = res;

            this.password = this.kiga.tabs[0].texts.find((text) => text.textKey === 'password').text as string;
            this.logo = this.kiga.tabs[0].files.find((file) => file.fileKey === 'logo').file;
        });
    }

    public login() {
        this.form.markAllAsTouched();

        if (this.passwordInput !== this.password) {
            this.errorWrongPassword = true;
            return;
        }

        this.setActiveKiga(this.kiga);
    }

    public setActiveKiga(kiga: LanguageContent): void {
        this.publicLanguageContentService.setActiveKiga(kiga);
        const slug = kiga.slug ? kiga.slug : '';
        this.router.navigate(['/kiga', slug]);
    }
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && isSubmitted);
    }
}
