import { Component, OnInit, OnDestroy, Optional, Inject } from '@angular/core';
import { PublicLanguageContentService } from 'src/app/services/public-language-content.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageContent } from 'src/app/models/language-content.interface';
import { S3File } from 'src/app/models/s3-file.interface';
import { forkJoin, Subscription } from 'rxjs';
import { PageLifecycleService } from 'src/app/services/page-lifecycle.service';
import { RefreshNotifyService } from 'src/app/services/refresh-notify.service';
import { PublicLanguageContentTabsService } from '../../services/public-language-content-tabs.service ';
import { LanguageContentTab } from '../../models/language-content-tab.interface';
import { AppComponent } from '../../app.component';
import { AngularFireAnalytics, ScreenTrackingService } from '@angular/fire/analytics';
import { LikeService } from '../../services/like.service';
import { environment } from 'src/environments/environment';
import { MongoUtil } from 'src/app/utils/mongo.util';

@Component({
    selector: 'app-post-overview',
    templateUrl: './post-overview.component.html',
    styleUrls: ['./post-overview.component.scss'],
})
export class PostOverviewComponent implements OnInit, OnDestroy {
    public posts: LanguageContent[] = [];
    public limitedPosts: LanguageContent[] = [];

    public postCategories: LanguageContent[] = [];

    public skip = 0;
    public limit = 20;
    public fetchLimit = 100;
    public postCount = 0;

    public infoHidden: boolean;
    public infoIcon: S3File;
    public infoTitle: string;
    public infoContent: string;

    private refreshSub: Subscription;
    private refreshNotifySub: Subscription;

    public slug: string;

    public kiga: LanguageContent;

    private body;

    constructor(
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly pageLifeCycleService: PageLifecycleService,
        private readonly refreshNotifyService: RefreshNotifyService,
        private readonly publicLanguageContentService: PublicLanguageContentService,
        @Optional() private appComponent: AppComponent,
        private readonly angularFireAnalytics: AngularFireAnalytics,
    ) {}

    ngOnInit(): void {
        this.infoHidden = localStorage.getItem('hideWelcomeBox') === 'true';

        this.body = document.getElementsByTagName('body')[0];
        this.body.onscroll = () => {
            if (this.body) {
                sessionStorage.setItem('lastScrollPos', this.body.scrollTop);
            }
        };

        this.slug = this.route.snapshot.paramMap.get('slug');
        if (this.slug) {
            this.publicLanguageContentService.findBySlug(this.slug, false, false).subscribe(
                (res) => {
                    if (!res) {
                        this.router.navigate(['/home']);
                    } else {
                        if (
                            !this.publicLanguageContentService.activeKiga ||
                            this.publicLanguageContentService.activeKiga.slug !== this.slug
                        ) {
                            this.router.navigate(['/kiga-login/' + this.slug]);
                        } else {
                            if (
                                !this.publicLanguageContentService.activeKiga.tabs ||
                                this.publicLanguageContentService.activeKiga.tabs.length === 0 ||
                                !this.publicLanguageContentService.activeKiga.tabs[0].texts.find(
                                    (x) => x.textKey === 'password',
                                ) ||
                                !res.tabs[0].texts.find((x) => x.textKey === 'password') ||
                                this.publicLanguageContentService.activeKiga.tabs[0].texts.find(
                                    (x) => x.textKey === 'password',
                                ).text !== res.tabs[0].texts.find((x) => x.textKey === 'password').text
                            ) {
                                this.publicLanguageContentService.setActiveKiga(null);
                                this.router.navigate(['/kiga-login/' + this.slug]);
                            }

                            this.angularFireAnalytics.logEvent('kiga/' + this.slug);

                            this.kiga = res;
                            this.init();
                        }
                    }
                },
                () => {
                    this.router.navigate(['/']);
                },
            );
        } else {
            if (!this.publicLanguageContentService.kigaLoaded) {
                this.publicLanguageContentService.kigaLoaded.subscribe((res) => {
                    this.savedKigaLoaded();
                });
            } else {
                this.savedKigaLoaded();
            }
        }
    }

    ngOnDestroy(): void {
        this.body = null;

        if (this.refreshNotifySub) {
            this.refreshNotifySub.unsubscribe();
        }
        if (this.refreshSub) {
            this.refreshSub.unsubscribe();
        }
    }

    private savedKigaLoaded() {
        if (this.publicLanguageContentService.activeKiga) {
            this.router.navigate(['/kiga', this.publicLanguageContentService.activeKiga.slug]);
        } else {
            this.router.navigate(['']);
        }
    }

    public get image(): S3File {
        return this.kiga.tabs[0].files[0].file;
    }

    public setActivePost(post: LanguageContent): void {
        this.publicLanguageContentService.activePost = post;

        sessionStorage.setItem('comingFromKiga', this.kiga.slug);

        const slug = post.slug ? post.slug : '';
        this.router.navigate(['kiga', this.kiga.slug, 'beitrag', slug]);
    }

    public hideWelcomeBox() {
        this.infoHidden = true;
        localStorage.setItem('hideWelcomeBox', String(true));
    }

    public loadMore() {
        this.limit += 20;
        if (this.fetchLimit < this.limit) {
            this.fetchLimit = this.limit;
            this.getData();
        } else {
            this.updateLimitedPosts();
        }
    }

    private init() {
        this.publicLanguageContentService.findAllPostCategories().subscribe((res: LanguageContent[]) => {
            this.postCategories = res;
        });

        this.publicLanguageContentService.getCountFromParent(this.kiga).subscribe((res: number) => {
            this.postCount = res;
        });

        this.getData();
        this.refreshSub = this.pageLifeCycleService.refresh.subscribe(() => {
            this.getData();
        });
        this.refreshNotifySub = this.refreshNotifyService.startLoad$.subscribe(() => {
            this.getData();
        });
    }

    private getData(): void {
        const parents = [MongoUtil.ref(this.kiga.content)];
        const showPostsFromParent = !!this.kiga.tabs[0].texts.find((text) => text.textKey === 'zeigeKommunaleBeitraege')
            ?.text;

        if (this.kiga.contentParents.length > 0 && showPostsFromParent) {
            parents.push(MongoUtil.ref(this.kiga.contentParents[0]));
        }
        this.publicLanguageContentService
            .findAllFromTemplateAndParents(parents, environment.postTemplateId, this.skip, this.fetchLimit, 'pubDate')
            .subscribe((res: LanguageContent[]) => {
                const reversedPosts = res.reverse();

                this.posts = reversedPosts.filter(
                    (post) => !post.tabs[0].texts.find((text) => text.textKey === 'oben-halten').text,
                );
                this.posts.unshift(
                    ...reversedPosts.filter(
                        (post) => post.tabs[0].texts.find((text) => text.textKey === 'oben-halten').text,
                    ),
                );
                this.refreshNotifyService.end();

                this.updateLimitedPosts();

                if (sessionStorage.getItem('comingFromKiga') === this.kiga.slug) {
                    setTimeout(() => {
                        if (this.body) {
                            this.body.scrollTop = sessionStorage.getItem('lastScrollPos');
                        }
                    }, 50);
                } else {
                    this.body.scrollTop = 0;
                }
            });

        const infoTitleData = this.kiga.tabs[1].texts.find((text) => text.textKey === 'headline');
        const infoContentData = this.kiga.tabs[1].texts.find((text) => text.textKey === 'content');

        if (infoTitleData) {
            this.infoTitle = infoTitleData.text as string;
        }
        if (infoContentData) {
            this.infoContent = infoContentData.text as string;
        }
    }

    private updateLimitedPosts() {
        this.limitedPosts = this.posts.slice(0, this.limit);
    }
}
