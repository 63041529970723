export class MongoUtil {
    public static ref(val: any): string | null {
        if (!val) {
            return null;
        }

        let id = val;
        if (val !== null && val !== undefined && typeof val === 'object' && val._id) {
            id = val._id;
        }
        return String(id);
    }

    public static refThrow(val: any): string {
        const id = MongoUtil.ref(val);
        if (!id) {
            throw new Error('Invalid ObjectId');
        }
        return id;
    }

    public static equal(val1: any, val2: any): boolean {
        const id1 = MongoUtil.ref(val1);
        const id2 = MongoUtil.ref(val2);

        return id1 !== null && id2 !== null && id1 === id2;
    }
}
